<template>
  <div class="market-choice buy-page">
    <div class="title-md">
      {{ $t("buy-sub-title") }}
    </div>

    <div class="market-choice__text">
      {{ $t("buy-sub-notice") }}
    </div>

    <div class="page-input" :class="{ error: payErrorAmount }">
      <input
        type="text"
        ref="pay"
        v-model="willPay"
        class="input"
        :placeholder="$t('input-pay')"
        @click="setCurrentField(fields[0])"
        readonly
        step="0.00000001"
      />

      <div class="caret" v-if="currentField === 'PAY'"></div>

      <div class="page-input__icon" v-if="currentOperation === 'SELL_SUB'">
        <CoinGoldSvg />
        SUB
      </div>
      <div class="page-input__icon" v-if="currentOperation === 'BUY_SUB'">
        <CoinSilverSvg />
        IGM
      </div>
      <div class="page-input__footer">
        <div class="error-msg">
          {{ payErrorAmount }}
        </div>
        <div class="page-input__notice" v-if="currentOperation === 'SELL_SUB'">
          <span>Min: {{ minPaySub }} SUB</span
          ><span>Max: {{ maxPaySub }} SUB</span>
        </div>
        <div class="page-input__notice" v-if="currentOperation === 'BUY_SUB'">
          <span>Min: {{ minPayIgm }} IGM</span
          ><span>Max: {{ maxPayIgm }} IGM</span>
        </div>
      </div>
    </div>

    <button class="icon-btn" @click="setOperation">
      <SwitcherSvg />
    </button>

    <div class="page-input" :class="{ error: getErrorAmount }">
      <input
        type="text"
        ref="get"
        v-model="willGet"
        class="input"
        :placeholder="$t('input-get')"
        @click="setCurrentField(fields[1])"
        readonly
        step="0.00000001"
      />
      <div class="caret" v-if="currentField === 'GET'"></div>
      <div class="page-input__icon" v-if="currentOperation === 'SELL_SUB'">
        <CoinSilverSvg />
        IGM
      </div>
      <div class="page-input__icon" v-if="currentOperation === 'BUY_SUB'">
        <CoinGoldSvg />
        SUB
      </div>
      <div class="error-msg">
        {{ getErrorAmount }}
      </div>
      <div class="page-input__footer" v-if="currentOperation === 'SELL_SUB'">
        <div class="page-input__notice">
          <span>Max: {{ maxGetIgm }} IGM</span>
        </div>
      </div>
      <div class="page-input__footer" v-if="currentOperation === 'BUY_SUB'">
        <div class="page-input__notice">
          <span>Max: {{ maxGetSub }} SUB</span>
        </div>
      </div>
    </div>

    <button
      @click="makeCoinSwap"
      v-if="currentOperation === 'SELL_SUB'"
      class="game-btn"
      :disabled="!willGet || !willPay || getErrorAmount || payErrorAmount"
    >
      Sell SUB
    </button>
    <button
      @click="makeCoinSwap"
      v-if="currentOperation === 'BUY_SUB'"
      class="game-btn"
      :disabled="!willGet || !willPay || getErrorAmount || payErrorAmount"
    >
      Buy SUB
    </button>

    <Keyboard @input="handleKeyboardInput" @clear="clearInput" />
  </div>
</template>

<script>
import CoinGoldSvg from "../../components/icons/CoinGoldSvg";
import SwitcherSvg from "../../components/icons/SwitcherSvg";
import CoinSilverSvg from "../../components/icons/CoinSilverSvg";
import Keyboard from "../../components/utils/Keyboard";
import api from "../../api/api";
import SetProcessingMixin from "../../mixins/general/SetProcessingMixin";
import contract from "../../api/contract";
import successes from "../../utils/successes";
import { mapActions } from "vuex";
import errors from "../../utils/errors";
export default {
  name: "SwapCoins",
  mixins: [SetProcessingMixin],
  data: () => ({
    operations: ["BUY_SUB", "SELL_SUB"],
    fields: ["PAY", "GET"],
    currentOperation: "SELL_SUB",
    currentField: "PAY",
    willPay: "",
    willGet: "",
    minPayIgm: 5,
    maxPayIgm: 1000,
    minPaySub: 5,
    maxPaySub: 100,
    maxGetIgm: 100,
    maxGetSub: 100,
    payErrorAmount: null,
    getErrorAmount: null,
    rete: 0,
    commission: 0,
  }),
  methods: {
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    async makeCoinSwap() {
      try {
        this.setLoading(true);
        let signature = null;
        if (this.currentOperation === "SELL_SUB") {
          const [error, val] = await contract.sellSubToSystem(
            +this.willPay + +this.commission * +this.willPay
          );
          if (error) {
            this.setError({ message: error?.message || errors.UNKNOWN_ERROR });
            return;
          }
          signature = val;
        }
        await api.makeCoinSwap({
          amount:
            this.currentOperation === "SELL_SUB" ? this.willPay : this.willGet,
          rate: this.rate,
          operation: this.currentOperation === "SELL_SUB" ? "SELL" : "BUY",
          sig: signature,
        });
        this.setLoading(false);
        this.loadBalance();
        this.setSuccess(successes.OPERATION_SUCCESSFUL);
      } catch (e) {
        console.log(e);
        this.setLoading(false);
        this.setError({ message: e.response.data.error.message });
      }
    },
    clearInput() {
      if (this.currentField === "PAY") {
        this.willPay = this.willPay
          .toString()
          .substring(0, this.willPay.toString().length - 1);
        this.$refs.pay.focus();
        this.calcSwap();
        this.validate();
      }
      if (this.currentField === "GET") {
        this.willGet = this.willGet
          .toString()
          .substring(0, this.willGet.toString().length - 1);
        this.$refs.get.focus();
        this.calcSwap();
        this.validate();
      }
    },
    setCurrentField(val) {
      this.currentField = val;
    },
    setOperation() {
      this.currentOperation =
        this.currentOperation === this.operations[0]
          ? this.operations[1]
          : this.operations[0];
      this.calcSwap();
      this.validate();
    },
    validate() {
      this.getErrorAmount = null;
      this.payErrorAmount = null;
      if (this.currentField === "PAY") {
        if (!this.willPay) {
          return;
        }
        if (this.currentOperation === this.operations[1]) {
          if (
            +this.willPay < this.minPaySub ||
            +this.willPay > this.maxPaySub
          ) {
            this.payErrorAmount = "Wrong pay SUB amount";
            return;
          }
          if (+this.willGet > this.maxGetIgm) {
            this.getErrorAmount = "Wrong get IGM amount";
            return;
          }
        }
        if (this.currentOperation === this.operations[0]) {
          if (
            +this.willPay < this.minPayIgm ||
            +this.willPay > this.maxPayIgm
          ) {
            this.payErrorAmount = "Wrong pay IGM amount";
            return;
          }
          if (+this.willGet > this.maxGetSub) {
            this.getErrorAmount = "Wrong get SUB amount";
            return;
          }
        }
      }

      if (this.currentField === "GET") {
        if (!this.willGet) {
          return;
        }
        if (this.currentOperation === this.operations[1]) {
          if (+this.willGet > this.maxGetIgm) {
            this.getErrorAmount = "Wrong get IGM amount";
            return;
          }
          if (
            +this.willPay < this.minPaySub ||
            +this.willPay > this.maxPaySub
          ) {
            this.payErrorAmount = "Wrong pay SUB amount";
            return;
          }
        }
        if (this.currentOperation === this.operations[0]) {
          if (
            +this.willPay < this.minPayIgm ||
            +this.willPay > this.maxPayIgm
          ) {
            this.payErrorAmount = "Wrong pay IGM amount";
            return;
          }
          if (+this.willGet > this.maxGetSub) {
            this.getErrorAmount = "Wrong get SUB amount";
          }
        }
      }
    },
    calcSwap() {
      if (this.currentField === "PAY" && this.currentOperation === "SELL_SUB") {
        if (!this.willPay) {
          this.willGet = "";
          return;
        }
        this.willGet = (
          +this.willPay * this.rate -
          this.willPay * this.commission
        ).toFixed(8);
      }
      if (this.currentField === "PAY" && this.currentOperation === "BUY_SUB") {
        if (!this.willPay) {
          this.willGet = "";
          return;
        }
        this.willGet = (
          +this.willPay / this.rate -
          this.willPay * this.commission
        ).toFixed(8);
      }
      if (this.currentField === "GET" && this.currentOperation === "SELL_SUB") {
        if (!this.willGet) {
          this.willPay = "";
          return;
        }
        this.willPay = (
          +this.willGet / this.rate +
          this.willGet * this.commission
        ).toFixed(8);
      }
      if (this.currentField === "GET" && this.currentOperation === "BUY_SUB") {
        if (!this.willGet) {
          this.willPay = "";
          return;
        }
        this.willPay = (
          +this.willGet * this.rate +
          this.willGet * this.commission
        ).toFixed(8);
      }
    },
    handleKeyboardInput(btn) {
      if (this.currentField === "PAY") {
        if (!this.willPay && btn === 0) return;
        this.willPay += btn;
        this.$refs.pay.focus();
        this.calcSwap();
        this.validate();
      }
      if (this.currentField === "GET") {
        if (!this.willGet && btn === 0) return;
        this.willGet += btn;
        this.$refs.get.focus();
        this.calcSwap();
        this.validate();
      }
    },
    async getRates() {
      try {
        const response = await api.getRates();
        this.rate = response.rate;
        this.commission = response.commission;
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.getRates();
    this.$refs.pay.focus();
  },
  components: { Keyboard, CoinSilverSvg, SwitcherSvg, CoinGoldSvg },
};
</script>

<style scoped></style>
