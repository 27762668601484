<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M10.2801 20.25H17.0001C19.7601 20.25 22.0001 18.01 22.0001 15.25V8.75C22.0001 5.99 19.7601 3.75 17.0001 3.75H10.2801C8.87005 3.75 7.53005 4.34 6.58005 5.39L3.05005 9.27C1.64005 10.82 1.64005 13.18 3.05005 14.73L6.58005 18.61C7.53005 19.66 8.87005 20.25 10.2801 20.25Z"
      fill="#9984D4"
    />
    <path
      d="M14.59 11.9997L16.53 10.0597C16.82 9.76973 16.82 9.28973 16.53 8.99973C16.24 8.70973 15.76 8.70973 15.47 8.99973L13.53 10.9397L11.59 8.99973C11.3 8.70973 10.82 8.70973 10.53 8.99973C10.24 9.28973 10.24 9.76973 10.53 10.0597L12.47 11.9997L10.53 13.9397C10.24 14.2297 10.24 14.7097 10.53 14.9997C10.68 15.1497 10.87 15.2197 11.06 15.2197C11.25 15.2197 11.44 15.1497 11.59 14.9997L13.53 13.0597L15.47 14.9997C15.62 15.1497 15.81 15.2197 16 15.2197C16.19 15.2197 16.38 15.1497 16.53 14.9997C16.82 14.7097 16.82 14.2297 16.53 13.9397L14.59 11.9997Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "ClearSvg",
};
</script>

<style scoped></style>
