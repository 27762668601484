<template>
  <button
    v-if="btn >= 0"
    class="keyboard-btn"
    :class="{ active: isBtnActive }"
    @click="click(btn)"
  >
    {{ btn }}
  </button>
  <button
    v-else
    class="keyboard-btn"
    :class="{ active: isBtnActive }"
    @click="click(btn)"
  >
    <ClearSvg />
  </button>
</template>

<script>
import ClearSvg from "@/components/icons/ClearSvg";
export default {
  name: "KeyboardButton",
  components: { ClearSvg },
  props: {
    btn: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isBtnActive: false,
  }),
  methods: {
    click(n) {
      this.isBtnActive = true;
      setTimeout(() => {
        this.isBtnActive = false;
      }, 100);
      this.$emit("click", n);
    },
  },
};
</script>
