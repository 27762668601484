<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M16.19 2H7.82C4.17 2 2 4.17 2 7.81V16.18C2 19.82 4.17 21.99 7.81 21.99H16.18C19.82 21.99 21.99 19.82 21.99 16.18V7.81C22 4.17 19.83 2 16.19 2Z"
      fill="#9984D4"
    />
    <path
      d="M10.18 6.1001C9.76996 6.1001 9.42996 6.4401 9.42996 6.8501V15.3501L7.66995 13.5901C7.37995 13.3001 6.89996 13.3001 6.60996 13.5901C6.31996 13.8801 6.31996 14.3601 6.60996 14.6501L9.64996 17.6901C9.71996 17.7601 9.79995 17.8101 9.88995 17.8501C9.97995 17.8901 10.08 17.9101 10.18 17.9101C10.28 17.9101 10.37 17.8901 10.47 17.8501C10.65 17.7701 10.8 17.6301 10.88 17.4401C10.92 17.3501 10.94 17.2501 10.94 17.1501V6.8501C10.93 6.4301 10.6 6.1001 10.18 6.1001Z"
      fill="#9984D4"
    />
    <path
      d="M17.3899 9.3601L14.3499 6.3201C14.2799 6.2501 14.1999 6.2001 14.1099 6.1601C13.9299 6.0801 13.7199 6.0801 13.5399 6.1601C13.3599 6.2401 13.2099 6.3801 13.1299 6.5701C13.0899 6.6601 13.0699 6.7601 13.0699 6.8601V17.1701C13.0699 17.5801 13.4099 17.9201 13.8199 17.9201C14.2299 17.9201 14.5699 17.5801 14.5699 17.1701V8.6701L16.3299 10.4301C16.4799 10.5801 16.6699 10.6501 16.8599 10.6501C17.0499 10.6501 17.2399 10.5801 17.3899 10.4301C17.6799 10.1201 17.6799 9.6501 17.3899 9.3601Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "SwitcherSvg",
};
</script>

<style scoped></style>
