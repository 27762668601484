<template>
  <div class="keyboard">
    <KeyboardButton v-for="n in 9" :key="n" @click="input" :btn="n" />
    <div class="keyboard__empty"></div>
    <KeyboardButton :btn="0" @click="input" />
    <KeyboardButton :btn="-1" @click="clear" />
  </div>
</template>

<script>
import KeyboardButton from "@/components/utils/KeyboardButton";

export default {
  name: "Keyboard",
  data: () => ({
    isBtnActive: false,
  }),
  methods: {
    input(n) {
      this.$emit("input", n);
    },
    clear() {
      this.$emit("clear");
    },
  },
  components: { KeyboardButton },
};
</script>

<style scoped></style>
